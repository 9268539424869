import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'

import Trafikverket from '../../images/information/trafikverket.png'
import Transportstyrelsen from '../../images/information/transportstyrelsen.png'
const TransportstyrelsenTrafikverket = () => (
  <Layout>
    <Seo title='Övriga avgifter' />
    <PageHeader text='Övriga avgifter' />
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3'>
          <h1 className='text-center'>Transportstyrelsen & Trafikverket</h1>
          <p>
            Både Trafikverket och Transportstyrelsen har fasta avgifter som du måste betala när du ska ta körkort.
            Nedanstående avgifter avser behörighet (B).
            För mera information om trafikverkets och transportstyrelsens avgifter och information om Transportstyrelsens och
            Trafikverkets Bank- och PlusGirokonton klicka här
          </p>

        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3 text-center offset-xl-3 offset-lg-3'>
          <h4>Fasta avgifter utöver trafikskolans priser</h4>
          <table className='table-borderless table-striped tabel-responsive '>

            <tbody>
              <tr />
              <tr>
                <td><img src={Trafikverket} alt='trafikverket' /></td>
                <td><img src={Transportstyrelsen} alt='transportstyrelsen' /></td>
              </tr>
              <tr>
                <td>Körkortstillstånd</td>
                <td>0 kr</td>
              </tr>
              <tr>
                <td>Körkortstillstånd efter återkallelse</td>
                <td>0 kr</td>
              </tr>
              <tr>
                <td>Kunskapsprov, dagtid</td>
                <td>325 kr</td>
              </tr>
              <tr>
                <td>Kunskapsprov efter 18.00 och helg</td>
                <td>400 kr</td>
              </tr>
              <tr>
                <td>Körprov för behörighet B</td>
                <td>800 kr</td>
              </tr>
              <tr>
                <td>Körprov efter 18.00 och helg</td>
                <td>1040 kr</td>
              </tr>
              <tr>
                <td>Trafikverkets bil vid uppkörning*</td>
                <td>500 kr</td>
              </tr>
              <tr>
                <td>Tillverkning av körkortet</td>
                <td>250 kr</td>
              </tr>
              <tr>
                <td>Fotografering (på Förarprovskontor)</td>
                <td>80 kr</td>
              </tr>
              <tr>
                <td>Godkännande som handledare</td>
                <td>50 kr</td>
              </tr>
            </tbody>
          </table>
          <p>
            <i> * Den bil som du använder vid ditt körprov måste ha dubbelkommando (pedaler även på passagerarsidan).
              En sådan bil kan Trafikverket tillhandahålla vid körprovet (gäller endast körprov för behörighet B).
            </i>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default TransportstyrelsenTrafikverket
